






































































.icons-deadline {
  display: block;
  font-size: 18px;
}
.btn_small {
  display: block;
  width: 80px;
  margin: 10px auto 0;
  &:first-child {
    margin-top: 0;
  }
}
