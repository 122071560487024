




























































































































































































































































































































// @import '~@/assets/css/print.css';
.box_print {
  padding: 75px 0 72px;
  background-color: #f5f5f5;
  border-top: 2px solid #222;
}
.wrap_print {
  overflow: hidden;
  position: relative;
  width: 1090px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.2);
}
.page_print + .page_print {
  border-top: 1px solid #e1e1e1;
}

.pdf-viewer {
  position: relative;
  border-top: 2px solid #222;
  & > span {
    max-width: 1280px;
    margin: 0 auto;
    border-top: 1px solid #e1e1e1;
    &:first-child {
      border-top: none;
    }
  }
  & .pdfviewer-error {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    &__btn {
      margin-top: 15px;
      font-size: 18px;
      & > .icon-realod {
        vertical-align: -4px;
      }
    }
  }
}
